@tailwind base;
@tailwind components;
@tailwind utilities;


/* Add to your global CSS */
.bg-stars {
    background: url('https://www.transparenttextures.com/patterns/stardust.png'); /* Example star texture */
    background-size: cover;
    animation: moveStars 20s linear infinite;
  }
  
  /* Star movement animation */
  @keyframes moveStars {
    0% {
      background-position: 0 0;
    }
    100% {
      background-position: 100% 100%;
    }
  }
  